import React from 'react'
import Layout from '../components/layout'
import remark from 'remark'
import html from 'remark-html'
import emoji from 'remark-emoji'
import remarkSlug from 'remark-slug'
import toc from 'remark-toc'
import { throttle } from 'lodash'
import { TutorialBaseHeader, TutorialContent } from '../templates/tutorialsBase'
import * as Prism from 'prismjs'
import { UnControlled as CodeMirror } from 'react-codemirror2'
import 'codemirror/lib/codemirror.css'
import 'codemirror/theme/material.css'
import 'codemirror/theme/base16-dark.css'
import 'codemirror/theme/base16-light.css'
import 'codemirror/theme/darcula.css'
import 'codemirror/theme/tomorrow-night-eighties.css'
import 'codemirror/theme/tomorrow-night-bright.css'
import './markdown-test-suite.css'

import Resizeable from 're-resizable'
import SEO from '../components/seo'
// const matter = require('parser-front-matter')
if (typeof window !== 'undefined' && window && typeof window.navigator !== 'undefined') {
  require('codemirror/mode/javascript/javascript')
  require('codemirror/mode/xml/xml')
  require('codemirror/mode/python/python')
  require('codemirror/mode/css/css')
  require('codemirror/mode/dockerfile/dockerfile')
  require('codemirror/mode/go/go')
  require('codemirror/mode/http/http')
  require('codemirror/mode/php/php')
  require('codemirror/mode/powershell/powershell')
  require('codemirror/mode/rust/rust')
  require('codemirror/mode/sass/sass')
  require('codemirror/mode/ruby/ruby')
  require('codemirror/mode/rust/rust')
  require('codemirror/mode/yaml/yaml')
  require('codemirror/mode/twig/twig')
  require('codemirror/mode/markdown/markdown')
  require('codemirror/mode/gfm/gfm')

  require('prismjs/components/prism-python')
  require('prismjs/components/prism-go')
  require('prismjs/components/prism-yaml')
  require('prismjs/components/prism-json')
  require('prismjs/components/prism-markdown')
  require('prismjs/components/prism-bash')
  require('prismjs/components/prism-textile')
  require('prismjs/components/prism-c')
  require('prismjs/components/prism-css')
  require('prismjs/components/prism-sass')
  require('prismjs/components/prism-ruby')
  require('prismjs/components/prism-php-extras')
}

class MarkdownTestSuite extends React.Component {
  constructor (props, context) {
    super(props, context)
    this.state = {
      code: '... loading',
      renderedMd: '... loading',
      error: false,
      isLeft: true,
      isFloating: true,
      toc: '....',
      fields: {
        slug: 'fasdf#',
        readingTime: {
          text: 'Some time',
          minutes: '42',
          time: '1000ms'
        }
      },
      fakeFrontmatter: {
        SPDXLicenseIdentifier: 'MIT',
        path: '/tutorials/tutorial-template',
        slug: 'tutorial-template',
        date: '2030-01-01',
        title: 'A great Tutorial Template!',
        // eslint-disable-next-line max-len
        short_description: 'We build a Tutorial Template, for the Frontmatter (first few lines before the actual content) please fill as much as possible. If you dont know what to put somewhere, just leave it empty, the Community manager will fill it for you',
        tags: ['Development', 'Lang:Go', 'Lang:JS'],
        author: 'Your Name',
        author_img: 'https://avatars3.githubusercontent.com/u/30047064?s=200&v=4',
        // eslint-disable-next-line max-len
        author_description: "I am manipulating arrays of characters in modern text editors that need more RAM than we used to do a flight to the moon. But it's super awesome.",
        header_img: 'Url to a header image, optional',
        cta: 'dedicated'
      }
    }

    this.updateCode = this.updateCode.bind(this)
    this.updateFrontmatter = this.updateFrontmatter.bind(this)
    this.updateMD = throttle(this.updateMD.bind(this), 50)
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    Prism.highlightAll()
  }

  componentWillMount () {
    if (typeof window !== 'undefined' && window && typeof window.navigator !== 'undefined') {
      window.fetch('https://raw.githubusercontent.com/hetzneronline/community-content/master/contributing.md')
        .then(response => {
          return response.text().then(text => {
            this.setState({
              code: text.replace('# Hetzner Tutorial Guidelines', '')
                .replace(
                  '(tutorial-template.md)',
                  '(https://github.com/hetzneronline/community-content/blob/master/tutorial-template.md)'
                )
            })
          })
        })
    }
  }

  updateMD (value) {
    const _val = `
## Table of Contents

- test


## PLEASE START WITH H2

${ value }`
    remark()
      .use(toc, { tight: true, maxDepth: 2 })
      .use(html)
      .use(remarkSlug)
      .use(emoji)
      .process(_val, (err, result) => {
        if (!(err && this.setState({ error: err }))) {
          const _content = result.toString()

          const [toc, contentOnly] = _content.split('PLEASE START WITH H2</h2>')

          let _toc = toc.replace('<h2 id="table-of-contents">Table of Contents</h2>', '')
          _toc = _toc.replace('<li><a href="#please-start-with-h2">PLEASE START WITH H2</a></li>', '')
          this.setState(
            { renderedMd: contentOnly, toc: _toc, err: false }
          )
        }
      })
  }

  updateFrontmatter (data) {
    const old = this.state.fakeFrontmatter
    this.setState({
      fakeFrontmatter: { ...old, ...data }
    })
  }

  updateCode (newCode) {
    this.setState({
      code: newCode
    })
  }

  render () {
    const codeOptions = {
      mode: {
        name: 'gfm',
        highlightFormatting: true,
        tokenTypeOverrides: {
          emoji: 'emoji'
        }
      },
      lineWrapper: true,
      spellcheck: true,
      theme: 'material',
      lineNumbers: true
    }

    return (
      <div className='min-w-100 h-100 vw-100 d-flex'>
        <Resizeable
          defaultSize={{
            width: 320
          }}
          className=' position-static h-100 vh-100'
          handleStyles={{
            right: {
              position: 'relative',
              userSelect: 'none',
              width: '24px',
              height: '100%',
              top: '0px',
              right: '-15px',
              zIndex: '10000000',
              cursor: 'col-resize',
              /* background:" #e32e3b", */
              padding: 'margin',
              /* margin:" 10px", */
              borderLeft: '10px solid green',
              float: 'right'
            }
          }}
          bounds='parent'
          enable={{
            top: false,
            right: true,
            bottom: false,
            left: false,
            topRight: false,
            bottomRight: false,
            bottomLeft: false,
            topLeft: false
          }}
        >

          <CodeMirror
            className='vh-100 position-fixed w-100' style={{ zIndex: 99999 }}
            value={this.state.code} options={codeOptions} onChange={(editor, data, value) => {
              this.setState({ rawMd: value })
              this.updateMD(value)
            }}
          />

        </Resizeable>
        <div className='w-100' style={{ overflowX: 'hidden' }}>
          <Layout noHeader>
            <SEO title={'A great Tutorial Template!'} description={'I am manipulating arrays of characters in modern text editors that need more RAM than we used to do a flight to the moon. But it\'s super awesome.'} path={'markdown-test-suite'} type={'website'} locale={'en'}/>
            <main>
              <TutorialBaseHeader frontmatter={this.state.fakeFrontmatter} markdownRemark={this.state} />
              <TutorialContent
                frontmatter={this.state.fakeFrontmatter}
                html={this.state.renderedMd}
                testMode
                toc={this.state.toc}
              />
            </main>
          </Layout>
        </div>
      </div>
    )
  }
}

export default MarkdownTestSuite
